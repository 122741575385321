import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  ReadOutlined,
  ShopOutlined,
  PhoneOutlined,
  BellOutlined,
  TeamOutlined
} from "@ant-design/icons";
import {
  ClockCircleOutline,
  CheckCircleOutline,
  GiftOutline,
  CloseCircleOutline,
} from "antd-mobile-icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";
//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/order/wait" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/order/wait",
    sidebar: {
      icon: <ClockCircleOutline />,
      label: "접수 대기",
    },
    component: loadable(() => import("../pages/order/WaitList")),
  },
  {
    exact: true,
    path: "/order/complete",
    sidebar: {
      icon: <CheckCircleOutline />,
      label: "접수 완료",
    },
    component: loadable(() => import("../pages/order/CompleteList")),
  },
  {
    exact: true,
    path: "/order/pickUp/wait",
    sidebar: {
      icon: <GiftOutline />,
      label: "픽업 대기",
    },
    component: loadable(() => import("../pages/order/PickupWaitList")),
  },
  {
    exact: true,
    path: "/order/pickUp",
    sidebar: {
      icon: <GiftOutline />,
      label: "픽업 완료",
    },
    component: loadable(() => import("../pages/order/PickupList")),
  },
  {
    exact: true,
    path: "/order/cancel",
    sidebar: {
      icon: <CloseCircleOutline />,
      label: "결제 취소",
    },
    component: loadable(() => import("../pages/order/CancelList")),
  },
  {
    exact: true,
    visible: false,
    path: "/order/detail/:id",
    component: loadable(() => import("../pages/order/Detail")),
  },
];
