import axios from "axios";

const customAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token") !== null ? sessionStorage.getItem("token") : localStorage.getItem("token")}`,
    },
});

customAxios.interceptors.request.use(
    (config) => {
        // URL 파라미터에서 토큰 값을 읽어와 localStorage에 추가
        const urlParams = new URLSearchParams(window.location.search);
        const urlAccessToken = urlParams.get('token');

        if (urlAccessToken) {
            localStorage.setItem("token", urlAccessToken);
        }

        // 요청 전에 localStorage에 있는 토큰 값을 다시 확인하여 설정
        config.headers.Authorization = `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

customAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log(error.response);
        if (error.response.status === 401) {
            localStorage.removeItem("token");
            sessionStorage.removeItem("token");
            window.location.href = "/";
            return Promise.reject(error);
        } else if (error.response.code === '401') {
            localStorage.removeItem("token");
            sessionStorage.removeItem("token");
            window.location.href = "/";
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

const useAxios = () => {
    return customAxios;
};

export default useAxios;
